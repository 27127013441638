import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import isEmpty from 'lodash/fp/isEmpty';
import filter from 'lodash/fp/filter';
import { Headline } from '@sumup/circuit-ui';
import { Grid } from '@sumup/circuit-ui/legacy';
import { css } from '@emotion/react';

import GroupedCardSchemesContext from '~/shared/providers/GroupedCardSchemesContext';
import dataSelector from '~/shared/util/data-selector';
import CardSchemesCollection from '~/shared/components/CardSchemesCollection';

export const SCHEME_MAP = {
  alelo: true,
  amex: true,
  applePay: true,
  bancoEstado: true,
  boleto: true,
  dankort: true,
  diners: true,
  discover: true,
  ec: true,
  elo: true,
  fasterPayments: true,
  googlePay: true,
  hiper: true,
  hipercard: true,
  lgbt: true,
  maestro: true,
  mastercard: true,
  nfc: true,
  pix: true,
  postFinance: true,
  redCompra: true,
  samsungPay: true,
  sepa: true,
  sodexo: true,
  ticket: true,
  unionPay: true,
  visa: true,
  visaElectron: true,
  vpay: true,
  vr: true,
  jcb: true,
};

const Container = styled.div`
  display: flex;
  row-gap: ${(p) => p.theme.spacings.exa};
  column-gap: ${(p) => p.theme.spacings.exa};

  & > :not(:first-of-type) {
    flex: 25%;
  }

  ${(p) => p.theme.mq.untilGiga} {
    flex-wrap: wrap;
    column-gap: 0;

    & > :first-of-type {
      flex: 100%;
    }

    & > :not(:first-of-type) {
      flex: ${(p) => (p.count === 3 ? '50%' : '100%')};
    }
  }

  ${(p) => p.theme.mq.untilMega} {
    flex-wrap: wrap;
    column-gap: 0;

    & > div,
    > :not(:first-of-type) {
      flex: 100%;
    }
  }
`;

const CardSchemeGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledHeadline = styled(Headline)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacings.giga};
  `,
);

/**
 * A GroupedCardScheme component which displays the supported card types separated in groups.
 */
function GroupedCardSchemes({ className }) {
  const groupedCardSchemesFromContext = useContext(GroupedCardSchemesContext);
  const cardSchemesGroupsCount = groupedCardSchemesFromContext.length;

  if (isEmpty(groupedCardSchemesFromContext)) {
    return null;
  }

  return (
    <Grid
      data-selector={dataSelector('section', 'grouped_card_schemes')}
      data-elbcontext="component:grouped_card_schemes"
    >
      <Container count={cardSchemesGroupsCount}>
        {groupedCardSchemesFromContext.map((cardSchemesGroup) => {
          const iconIds = filter(
            (scheme) => SCHEME_MAP[scheme],
            cardSchemesGroup.cardSchemes,
          );
          return (
            <CardSchemeGroup
              key={cardSchemesGroup.id}
              data-selector={dataSelector('schemes_list', 'card_schemes')}
            >
              <StyledHeadline size={'two'} as="h2">
                {cardSchemesGroup.groupTitle}
              </StyledHeadline>
              <CardSchemesCollection className={className} iconIds={iconIds} />
            </CardSchemeGroup>
          );
        })}
      </Container>
    </Grid>
  );
}

GroupedCardSchemes.propTypes = {
  className: PropTypes.string,
  cardSchemes: PropTypes.arrayOf(PropTypes.string),
};

/**
 * @component
 */
export default GroupedCardSchemes;
